export default [
  {
    year: 'start',
    label: 'Übersicht',
    boundingBox: [
      [85.04, -179.89],
      [-70.6, 171.51],
    ],
    startCoords: [40, 0],
    areas: [],
  },
  {
    year: 1978,
    label: '1978-1981',
    boundingBox: [
      [75.88, -150.9],
      [50.81, -150.88],
    ],
    startCoords: [72.5, -137.28],
    areas: [
      // {
      //   id: "1649696e-173e-48a9-9d3e-5ba02c9e4999",
      //   date: "1994-11-10",
      //   people: "",
      //   text: "",
      //   coords: [
      //     [80.7, -166],
      //     [81.45, -152.8],
      //     [81.38, -137.65],
      //     [80.72, -128.74],
      //     [77.42, -164.24],
      //   ],
      // },
    ],
  },
  {
    year: 1983,
    label: '1983-1987',
    boundingBox: [
      [77.48, -116.55],
      [19.27, -78.27],
    ],
    startCoords: [72.5, -97.76],
    areas: [],
  },
  {
    year: 1987,
    label: '1987-1988',
    boundingBox: [
      [77.48, -116.55],
      [19.27, -78.27],
    ],
    startCoords: [72.5, -56.12],
    areas: [],
  },
  {
    year: 1988,
    label: '1988-1990',
    boundingBox: [
      [77.48, -116.55],
      [19.27, -78.27],
    ],
    startCoords: [72.5, -16.42],
    areas: [],
  },
  {
    year: 1990,
    label: '1990-1991',
    boundingBox: [
      [77.48, -116.55],
      [19.27, -78.27],
    ],
    startCoords: [72.5, 26.67],
    areas: [],
  },
  {
    year: 1991,
    label: '1991-1994',
    boundingBox: [
      [77.48, -116.55],
      [19.27, -78.27],
    ],
    startCoords: [72.5, 76.9],
    areas: [],
  },
  {
    year: 1994,
    label: '1994-1996',
    boundingBox: [
      [77.48, -116.55],
      [19.27, -78.27],
    ],
    startCoords: [72.5, 128.53],
    areas: [],
  },
  {
    year: 1996,
    label: '1996-1998',
    boundingBox: [
      [77.48, -116.55],
      [19.27, -78.27],
    ],
    startCoords: [30, -137.68],
    areas: [],
  },
  {
    year: 1998,
    label: '1998-1999',
    boundingBox: [
      [77.48, -116.55],
      [19.27, -78.27],
    ],
    startCoords: [30, -56.36],
    areas: [],
  },
  {
    year: 1999,
    label: '1999-2000',
    boundingBox: [
      [77.48, -116.55],
      [19.27, -78.27],
    ],
    startCoords: [30, -15.3],
    areas: [],
  },
  {
    year: 2000,
    label: '2000-2001',
    boundingBox: [
      [77.48, -116.55],
      [19.27, -78.27],
    ],
    startCoords: [30, 27.83],
    areas: [],
  },
  {
    year: 2003,
    label: '2003-2004',
    boundingBox: [
      [77.48, -116.55],
      [19.27, -78.27],
    ],
    startCoords: [30, 75.79],
    areas: [],
  },
  {
    year: 2004,
    label: '2004-2006',
    boundingBox: [
      [77.48, -116.55],
      [19.27, -78.27],
    ],
    startCoords: [28, 128.29],
    areas: [],
  },
  {
    year: 2006,
    label: '2006-2007',
    boundingBox: [
      [77.48, -116.55],
      [19.27, -78.27],
    ],
    startCoords: [-45, -137.44],
    areas: [],
  },
  {
    year: 2008,
    label: '2008-2010',
    boundingBox: [
      [77.48, -116.55],
      [19.27, -78.27],
    ],
    startCoords: [-38, -95.35],
    areas: [],
  },
  {
    year: 2010,
    label: '2010-2011',
    boundingBox: [
      [77.48, -116.55],
      [19.27, -78.27],
    ],
    startCoords: [-38, -56.88],
    areas: [],
  },
  {
    year: 2011,
    label: '2011-2012',
    boundingBox: [
      [77.48, -116.55],
      [19.27, -78.27],
    ],
    startCoords: [-35, -15.83],
    areas: [],
  },
  {
    year: 2012,
    label: '2012-2014',
    boundingBox: [
      [77.48, -116.55],
      [19.27, -78.27],
    ],
    startCoords: [-24.55, 28.16],
    areas: [],
  },
  {
    year: 2014,
    label: '2014-2016',
    boundingBox: [
      [77.48, -116.55],
      [19.27, -78.27],
    ],
    startCoords: [-35, 77.88],
    areas: [],
  },
  {
    year: 2016,
    label: '2016-2017',
    boundingBox: [
      [77.48, -116.55],
      [19.27, -78.27],
    ],
    startCoords: [-42, 129.01],
    areas: [],
  },
];
