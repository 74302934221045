<template>
  <div class="home">
    <div id="map-wrap" style="height: 100vh">
      <l-map
        ref="map"
        :options="{ attributionControl: false, zoomControl: false }"
        :zoom="3"
        :max-zoom="7"
        :min-zoom="minZoom"
        :center="[40, 0]"
      >
        <l-control-zoom :position="zoomControlPosition" />

        <l-control position="topright">
          <year-nav :mobile="mobile" />
        </l-control>

        <l-tile-layer
          url="/tiles/{z}/{x}/{y}.png"
          :no-wrap="true"
        ></l-tile-layer>
        <!-- <l-polygon
          v-for="area in areas"
          :key="area.date"
          :lat-lngs="area.coords"
          color="#222"
          :weight="0"
          :opacity="0"
          fill-color="black"
          :fill-opacity="fillOpacity"
          :smoothFactor="2"
          @click="onPolygonClick($event, area.id)"
        ></l-polygon> -->
      </l-map>
    </div>
  </div>
</template>

<script>
// import { LPolygon } from "vue2-leaflet";
import { LControlZoom, LControl } from "vue2-leaflet";
import sheets from "../guestbook-data";
import YearNav from "@/components/YearNav.vue";

export default {
  name: "Home",
  components: {
    // LPolygon,
    LControlZoom,
    LControl,
    YearNav,
  },

  data() {
    return {
      // debug: false,
      mobile: false,
      zoomControlPosition: "topleft",
    };
  },

  watch: {
    $route() {
      this.moveToStart();
    },
  },

  created() {
    this.onResize();
  },

  mounted() {
    // this.map.on("click", function (e) {
    //   var coord = e.latlng;
    //   var lat = coord.lat;
    //   var lng = coord.lng;
    //   console.log(
    //     "[" +
    //       Math.round(lat * 100) / 100 +
    //       ", " +
    //       Math.round(lng * 100) / 100 +
    //       "],"
    //   );
    // });

    window.addEventListener("resize", this.onResize);

    this.moveToStart();
  },

  computed: {
    map() {
      return this.$refs.map.mapObject;
    },

    minZoom() {
      return this.mobile ? 1 : 2;
    },

    flyToZoom() {
      return this.mobile ? 4 : 5;
    },

    routeYear() {
      return this.$route.params.year || "start";
    },

    routeSheetData() {
      const selectedYear =
        this.routeYear === "start" ? "start" : parseInt(this.routeYear);
      return sheets.find((sheet) => sheet.year === selectedYear);
    },

    areas() {
      return sheets.map((sheet) => sheet.areas).flat();
    },

    // fillOpacity() {
    //   return this.debug ? 0.05 : 0;
    // },
  },

  methods: {
    onResize() {
      this.mobile = window.innerWidth <= 800;
      this.zoomControlPosition =
        window.innerWidth <= 360 ? "bottomleft" : "topleft";
    },

    moveToStart() {
      if (!this.routeSheetData) {
        return;
      }
      const startCoords = this.routeSheetData.startCoords;
      if (this.mobile) {
        startCoords[0] = startCoords[0] - 3;
      }
      // this.flyToBounds(this.routeSheetData.boundingBox);
      this.map.flyTo(
        startCoords,
        this.routeSheetData.year === "start" ? this.minZoom : this.flyToZoom
      );
    },

    flyToBounds(bounds) {
      this.map.flyToBounds(bounds);
    },

    // onPolygonClick(e, entryId) {
    //   console.log(entryId);
    //   this.flyToBounds(e.target.getBounds());
    // },
  },
};
</script>

<style>
.leaflet-tile-container img {
  width: 256.5px !important;
  height: 256.5px !important;
}
</style>
