import Vue from "vue";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import vSelect from 'vue-select';
import "leaflet/dist/leaflet.css";
import 'vue-select/dist/vue-select.css';
import "normalize.css";

import App from "./App.vue";
import router from "./router";

Vue.config.productionTip = false;

Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);
Vue.component('v-select', vSelect)

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
