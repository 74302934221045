<template>
  <div id="nav">
    <button class="prev-next prev" @click="onClickPrev">
      <svg
        width="13"
        height="22"
        viewBox="0 0 13 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.9175 0L0 11L10.9175 22L13 19.9018L4.1649 11L13 2.09818L10.9175 0Z"
          fill="#2C2C2C"
        />
      </svg>
    </button>
    <v-select
      class="year-selector"
      :options="options"
      :searchable="false"
      :clearable="false"
      :value="selectedYear"
      @input="onYearChange"
    ></v-select>
    <button class="prev-next next" @click="onClickNext">
      <svg
        width="13"
        height="22"
        viewBox="0 0 13 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.08245 0L13 11L2.08245 22L0 19.9018L8.8351 11L0 2.09818L2.08245 0Z"
          fill="#2C2C2C"
        />
      </svg>
    </button>
  </div>
</template>

<script>
import sheets from "../guestbook-data";

export default {
  data() {
    return {
      navOpen: true,
      selectedYear: {
        label: "Jahr wählen...",
        value: "start",
      },
    };
  },

  mounted() {
    if (this.currentSheet) {
      this.setYearSelection();
    }
  },

  computed: {
    routeYear() {
      return this.$route.params.year || "start";
    },

    currentSheetIndex() {
      const selectedYear =
        this.routeYear === "start" ? "start" : parseInt(this.routeYear);
      return sheets.findIndex((sheet) => sheet.year === selectedYear);
    },

    currentSheet() {
      return sheets[this.currentSheetIndex];
    },

    options() {
      return sheets.map((sheet) => {
        return {
          label: sheet.label,
          value: sheet.year,
        };
      });
    },
  },

  methods: {
    isActive(year) {
      return this.$route.params.year === year;
    },

    onYearChange(year) {
      if (this.$route.params.year === year.value) {
        return;
      }
      this.goTo(year.value);
    },

    setYearSelection() {
      if (!this.currentSheet) {
        return;
      }
      this.selectedYear = {
        label: this.currentSheet.label,
        year: this.currentSheet.year,
      };
    },

    goTo(year) {
      this.$router.push({ name: "Year", params: { year } });
      this.setYearSelection();
    },

    onClickNext() {
      let nextIndex = this.currentSheetIndex + 1;
      if (nextIndex >= sheets.length) {
        nextIndex = 0;
      }
      this.goTo(sheets[nextIndex].year);
    },

    onClickPrev() {
      let prevIndex = this.currentSheetIndex - 1;
      if (prevIndex === -1) {
        prevIndex = sheets.length - 1;
      }
      this.goTo(sheets[prevIndex].year);
    },
  },
};
</script>

<style>
#nav {
  padding-left: 10px;
  display: flex;
  flex-direction: row;
}

#nav > * {
  margin-left: 10px;
}

#nav button.prev-next {
  background-color: #fff;
  color: #2c3e50;
  border: none;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  width: 50px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
#nav button.prev-next svg {
  height: 20px;
  width: auto;
}

#nav button.active {
  background-color: #fff;
}

.year-selector .vs__dropdown-toggle {
  background-color: #fff;
  border: none;
  padding: 9px 15px;
  width: 180px;
  font-weight: bold;
  font-size: 16px;
}
.year-selector .vs__clear,
.year-selector .vs__open-indicator {
  fill: #2c3e50;
}
.year-selector .vs__dropdown-menu {
  font-size: 16px;
}
.year-selector .vs__dropdown-menu li {
  border-top: 1px solid #e5e5e5;
  padding: 7px 10px;
  font-weight: bold;
}
</style>
